import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import SignIn from './screens/SignIn';
import Details from './screens/Details';
import Edu from './screens/Edu';
import EarlyBird from './screens/EarlyBird';
import GreenfeeAbkommen from './screens/GreenfeeAbkommen';
import GreenfeeCards from './screens/GreenfeeCards';
import ClubVerbuende from './screens/ClubVerbuende';
import Sonstige from './screens/Sonstige';
import TopDeals from './screens/TopDeals';
import MemberDeal from './screens/MemberDeal';
import Aufschlaege from './screens/Aufschlaege';
import PasswordReset from './screens/PasswordReset';
import EmailVerification from './screens/EmailVerification';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './helper/RouteProtect';

function App() {
  return (
    <div>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<SignIn />} />
            <Route
              exact
              path="/pwreset/:resetToken"
              element={<PasswordReset />}
            />
            <Route
              exact
              path="/verifyemail/:emailVerificationToken"
              element={<EmailVerification />}
            />

            <Route element={<PrivateRoute />}>
              <Route exact path="/details" element={<Details />} />
              <Route exact path="/deals/edu" element={<Edu />} />
              <Route exact path="/deals/topdeals" element={<TopDeals />} />
              <Route exact path="/deals/earlybird" element={<EarlyBird />} />
              <Route
                exact
                path="/deals/greenfeeabkommen"
                element={<GreenfeeAbkommen />}
              />
              <Route
                exact
                path="/deals/greenfeecards"
                element={<GreenfeeCards />}
              />
              <Route
                exact
                path="/deals/clubverbuende"
                element={<ClubVerbuende />}
              />
              <Route exact path="/deals/sonstige" element={<Sonstige />} />
              <Route exact path="/deals/memberdeal" element={<MemberDeal />} />
              <Route
                exact
                path="/deals/aufschlaege"
                element={<Aufschlaege />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
