import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import golfClubReducer from './redux/reducers/golfClubReducer';
import generalReducer from './redux/reducers/generalReducer';
import { PersistGate } from 'redux-persist/integration/react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// Bugsnag.start({
//   apiKey: "0979a1d6a53675feafdd8c6394d2d242",
//   plugins: [new BugsnagPluginReact()],
// });

// const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

Bugsnag.start({
  apiKey: '0979a1d6a53675feafdd8c6394d2d242',
  plugins: [new BugsnagPluginReact()],
  // Configure to reduce resource usage
  autoTrackSessions: false, // Disable auto-tracking of sessions if not needed
  onError: (event) => {
    // Add any custom error handling logic here
    // Ensure it is efficient and does not cause excessive resource usage
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// Manually start a session when appropriate
const startBugsnagSession = () => {
  Bugsnag.startSession();
};

import App from './App';

import * as serviceWorker from './serviceWorker';
const persistConfig = {
  key: 'root',
  storage: storage,
};
// Persist
const rootReducer = combineReducers({
  golfClub: golfClubReducer,
  general: generalReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
let persistor = persistStore(store);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
