const initialState = {};

const golfClubDetailsReducer = (state = initialState, action) => {
  const {
    golfClubDetails,
    golfClubCourses,
    tmpCourseId,
    tmpDealCourses,
    newDealCourses,
  } = action;
  switch (action.type) {
    case "SET_GOLFCLUB_DETAILS":
      return { ...state, golfClubDetails };
    case "SET_GOLFCLUB_COURSES":
      return { ...state, golfClubCourses };
    case "SET_TMP_COURSEID":
      return { ...state, tmpCourseId };
    case "SET_TMP_DEAL_COURSES":
      return { ...state, tmpDealCourses };
    case "SET_NEW_DEAL_COURSES":
      return { ...state, newDealCourses };

    default:
      return state;
  }
};

export default golfClubDetailsReducer;
