// GolfClubDetails
export const SET_GOLFCLUB_DETAILS = "SET_GOLFCLUB_DETAILS";
export const setGolfClubDetails = (details) => {
  return { type: SET_GOLFCLUB_DETAILS, golfClubDetails: details };
};

export const SET_GOLFCLUB_COURSES = "SET_GOLFCLUB_COURSES";
export const setGolfClubCourses = (courses) => {
  return { type: SET_GOLFCLUB_COURSES, golfClubCourses: courses };
};
// Set Tmp Course ID
export const SET_TMP_COURSEID = "SET_TMP_COURSEID";
export const setTmpCourseId = (id) => {
  return { type: SET_TMP_COURSEID, tmpCourseId: id };
};
// Set Tmp Course ID
export const SET_TMP_DEAL_COURSES = "SET_TMP_DEAL_COURSES";
export const setTmpDealCourses = (courses) => {
  return { type: SET_TMP_DEAL_COURSES, tmpDealCourses: courses };
};
// Set Tmp Course ID
export const SET_NEW_DEAL_COURSES = "SET_NEW_DEAL_COURSES";
export const setNewDealCourses = (courses) => {
  return { type: SET_NEW_DEAL_COURSES, newDealCourses: courses };
};
