/* eslint-disable */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SiderComponent from './../components/SiderComponent';
import HeaderComponent from './../components/HeaderComponent';
import DealPicturePlaceholder from './../assets/DealPicturePlaceholder.jpg';
import { formatedDealPeriodObject, refresh } from '../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  setGolfClubDetails,
  setTmpCourseId,
  setTmpDealCourses,
  setNewDealCourses,
} from '../redux/actions/golfClubActions';

import { DeleteFilled, SaveFilled } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/de_DE';
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Select,
  DatePicker,
  Upload,
} from 'antd';
import greenfeeDealsApi from '../api/greenfeeDeals';
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

function TopDeals(props) {
  // SESSION PARAMS
  const sessionBnd = localStorage.getItem('sessionBnd');
  const headers = {
    headers: { Authorization: `Bearer ${sessionBnd}` },
  };
  // REDUX STUFF
  const dispatch = useDispatch();
  const golfClubDetails = useSelector(
    (state) => state.golfClub.golfClubDetails
  );

  if (!golfClubDetails) {
    props.history.push(`/`);
    return <div></div>;
  }

  if (!golfClubDetails.topDeals) {
    golfClubDetails.topDeals = [];
  }

  // FORM STUFF
  const [form] = Form.useForm();
  const { Option } = Select;
  const dateFormat = 'YYYY/MM/DD';
  const golfClubId = golfClubDetails._id.golfClub;

  // New Deals
  const saveNewDeal = async (values) => {
    console.log(values);
    let newDeal = values.newDealValues;
    if (newDeal.discountType === '%') {
      newDeal.discountPercent = newDeal.discountAmount;
    } else {
      newDeal.discountPrice = newDeal.discountAmount;
    }

    const dealPeriod = formatedDealPeriodObject(values.newDealPeriod);
    newDeal.dealPeriod = dealPeriod;

    try {
      const postDeal = await greenfeeDealsApi.post(
        `/golfclubs/${golfClubId}/topDeals`,
        newDeal,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      form.resetFields();
    } catch (error) {}
  };
  // UPDATE Deals
  const saveExisDeal = async (values) => {
    let deal = values.dealValues;
    if (deal.discountType === '%') {
      deal.discountPercent = deal.discountAmount;
      deal.discountPrice = '';
    } else {
      deal.discountPrice = deal.discountAmount;
      deal.discountPercent = '';
    }
    if (values.dealPeriod) {
      const dealPeriod = formatedDealPeriodObject(values.dealPeriod);
      deal.dealPeriod = dealPeriod;
    }
    try {
      const updateDeal = await greenfeeDealsApi.patch(
        `/golfclubs/${golfClubId}/topDeals/${deal._id}`,
        deal,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      refresh();
    } catch (error) {}
  };
  //DELETE deal
  const deleteDeal = async (dealId) => {
    try {
      const postDeal = await greenfeeDealsApi.delete(
        `/golfclubs/${golfClubId}/topDeals/${dealId}`,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      //window.location.reload(false);
    } catch (error) {}
  };
  // UPLOAD IMAGE
  const uploadImage = async (options, id) => {
    const { onSuccess, onError, file, onProgress } = options;
    const uploadImageData = new FormData();
    uploadImageData.append('dealImage', file);
    try {
      const golfClubImage = await greenfeeDealsApi.patch(
        `/golfclubs/${golfClubId}/topdeals/${id}`,
        uploadImageData,
        headers
      );
      // Get Current Values from Server
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        console.log('Error no Golfclub found for your Admin Account');
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err });
    }
  };

  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <SiderComponent props={props} />
        <Layout>
          <HeaderComponent />
          <Content
            style={{
              backgroundColor: '#EAEAEA',
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 50,
            }}
          >
            <h3 style={{ marginTop: 30 }}>Top Deals</h3>
            <h5 style={{ marginTop: 30 }}>Neuen Deal anlegen</h5>
            <h6 style={{ marginTop: 0 }}>
              Das Bild zum Deal wird anschließend im bestehenden Deal
              hochgeladen
            </h6>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 15,
                width: '100%',
                padding: 30,
                marginTop: 20,
              }}
            >
              <Form
                onFinish={saveNewDeal}
                //hideRequiredMark
                form={form}
                initialValues={{
                  newDealValues: {
                    discountType: '%',
                  },
                }}
              >
                <Row>
                  <Col span={20}>
                    <Row>
                      <Col style={{ width: 480, paddingRight: 10 }}>
                        <Form.Item name={['newDealValues', 'title']}>
                          <Input
                            size="big"
                            placeholder="Titel (10 - 65 Zeichen)"
                            required
                            maxLength={65}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: 55 }}>
                        <Form.Item name={['newDealValues', 'discountType']}>
                          <Select defaultValue="%" size="big">
                            <Option value="%">%</Option>
                            <Option value="€">€</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col style={{ paddingRight: 10 }}>
                        <Form.Item name={['newDealValues', 'discountAmount']}>
                          <InputNumber
                            size="big"
                            style={{ width: 60 }}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name={['newDealPeriod']}>
                          <RangePicker
                            allowClear={false}
                            size="big"
                            format={dateFormat}
                            locale={locale}
                            style={{ width: 245 }}
                            required
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4} className="pricingCol">
                    <Row
                      style={{
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Col
                        span={20}
                        style={{
                          height: '100%',
                        }}
                      >
                        <Row
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              minWidth: 85,
                              minHeight: 40,
                            }}
                          >
                            Speichern
                          </Button>
                        </Row>
                      </Col>
                      <Col span={4}></Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row style={{ marginTop: 10 }}>
                      <Col style={{ width: 250, marginRight: 20 }}>
                        <Form.Item name={['newDealValues', 'teaser']}>
                          <TextArea
                            rows={13}
                            placeholder="Teaser (70 - 300 Zeichen)"
                            maxLength={300}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: 700, marginRight: 20 }}>
                        <Form.Item name={['newDealValues', 'description']}>
                          <TextArea
                            rows={13}
                            placeholder="Beschreibung (150 - 1000 Zeichen)"
                            maxLength={1000}
                            required
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ marginTop: 10 }}>
                    <Col style={{ width: 560, paddingRight: 10 }}>
                      <Form.Item name={['newDealValues', 'dealUrl']}>
                        <Input size="big" placeholder="URL" required />
                      </Form.Item>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </div>
            <hr
              style={{
                border: '0.3px dotted rgba(0, 0, 0, 0.85)',
                marginTop: 30,
              }}
            />
            <h5 style={{ marginTop: 30 }}>Bestehende Deals editieren</h5>
            {golfClubDetails.topDeals.map((deal, index) => {
              let dealPeriod = [];
              if (deal.discountPrice) {
                deal.discountType = '€';
                deal.discountAmount = deal.discountPrice;
              } else {
                deal.discountType = '%';
                deal.discountAmount = deal.discountPercent;
              }
              if (deal.dealPeriod) {
                dealPeriod = [
                  moment(deal.dealPeriod.from, dateFormat),
                  moment(deal.dealPeriod.to, dateFormat),
                ];
              }
              return (
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 15,
                    width: '100%',
                    padding: 30,
                    marginTop: 20,
                  }}
                  key={deal._id}
                >
                  <Form
                    onFinish={saveExisDeal}
                    hideRequiredMark
                    initialValues={{
                      dealValues: deal,
                    }}
                  >
                    <Form.Item
                      name={['dealValues', '_id']}
                      className="noHeight"
                    >
                      <span />
                    </Form.Item>
                    <Row>
                      <Col span={20}>
                        <Row>
                          <Col style={{ width: 480, paddingRight: 10 }}>
                            <Form.Item name={['dealValues', 'title']}>
                              <Input
                                size="big"
                                placeholder="Titel"
                                maxLength={65}
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: 55 }}>
                            <Form.Item name={['dealValues', 'discountType']}>
                              <Select defaultValue="%" size="big">
                                <Option value="%">%</Option>
                                <Option value="€">€</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col style={{ paddingRight: 10 }}>
                            <Form.Item name={['dealValues', 'discountAmount']}>
                              <InputNumber size="big" style={{ width: 60 }} />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name={['dealPeriod']}>
                              <RangePicker
                                allowClear={false}
                                size="big"
                                format={dateFormat}
                                locale={locale}
                                style={{ width: 245 }}
                                defaultValue={dealPeriod}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={4} className="pricingCol">
                        <Row
                          style={{
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <Col
                            span={20}
                            style={{
                              height: '100%',
                            }}
                          >
                            <Row
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                              }}
                            >
                              <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                  minWidth: 85,
                                  minHeight: 40,
                                }}
                              >
                                Speichern
                              </Button>
                            </Row>
                          </Col>
                          <Col span={4}></Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row style={{ marginTop: 10 }}>
                          <Col style={{ width: 230, marginRight: 20 }}>
                            <Form.Item name={['dealValues', 'teaser']}>
                              <TextArea
                                rows={8}
                                placeholder="Teaser"
                                maxLength={300}
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: 300, marginRight: 20 }}>
                            <Form.Item name={['dealValues', 'description']}>
                              <TextArea
                                rows={8}
                                placeholder="Beschreibung"
                                maxLength={1000}
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: 400 }}>
                            <Upload
                              name="avatar"
                              showUploadList={false}
                              customRequest={(options) => {
                                uploadImage(options, deal._id);
                              }}
                            >
                              {deal.dealImage ? (
                                <img
                                  src={`https://greenfeedeals2.s3.eu-central-1.amazonaws.com/${deal.dealImage}`}
                                  alt="avatar"
                                  style={{ width: '100%', cursor: 'pointer' }}
                                />
                              ) : (
                                <img
                                  src={DealPicturePlaceholder}
                                  alt="avatar"
                                  style={{ width: '100%', cursor: 'pointer' }}
                                />
                              )}
                            </Upload>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={19} style={{ marginTop: 10 }}>
                        <Col style={{ width: 560, paddingRight: 10 }}>
                          <Form.Item name={['dealValues', 'dealUrl']}>
                            <Input size="big" placeholder="URL" />
                          </Form.Item>
                        </Col>
                      </Col>
                      <Col
                        span={4}
                        style={{
                          marginTop: 10,
                          textAlign: 'right',
                          paddingRight: 10,
                        }}
                      >
                        <Col>
                          <Col>
                            <Button
                              danger
                              type="primary"
                              icon={<DeleteFilled />}
                              size="small"
                              onClick={() => deleteDeal(deal._id)}
                            />
                          </Col>
                        </Col>
                      </Col>
                    </Row>
                  </Form>
                </div>
              );
            })}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default TopDeals;
