import "intl";
import "intl/locale-data/jsonp/de";
const safeStringify = require("fast-safe-stringify");
import Bugsnag from "@bugsnag/js";

const moment = require("moment");

export const getDeEur = function (price) {
  return new Intl.NumberFormat("de", {
    style: "currency",
    currency: "EUR",
  }).format(price);
};
export const bugsnagnotify = function (message) {
  console.log(message);
  Bugsnag.notify(new Error(message));
};

export const refresh = function () {
  window.location.reload();
};
export const boolToString = function (bool) {
  if (bool === true) {
    return "ja";
  }
  return "nein";
};

export const formatIsoDate = function (date) {
  return moment(date).format("DD.MM.YYYY");
};

export const formatedDealPeriodObject = function (momentObj) {
  if (momentObj) {
    let dealperiodFrom = moment(momentObj[0]).toDate();
    dealperiodFrom = moment(dealperiodFrom).format("YYYY-MM-DD");
    let dealperiodTo = moment(momentObj[1]).toDate();
    dealperiodTo = moment(dealperiodTo).format("YYYY-MM-DD");
    const dealPeriodObject = {
      from: dealperiodFrom,
      to: dealperiodTo,
    };
    return dealPeriodObject;
  } else {
    return;
  }
};
