import React, { createContext, useContext, useState, useEffect } from 'react';
import { setGolfClubDetails } from '../redux/actions/golfClubActions';
import {
  setDiscountCards,
  setMembershipTypes,
  setAlliances,
  setUserDetails,
} from '../redux/actions/generalActions';
import config from '../config';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import jwt from 'jsonwebtoken';
import greenfeeDealsApi from '../api/greenfeeDeals';

const AuthContext = createContext();
import axios from 'axios';

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const login = () => setIsAuthenticated(true);
  const logout = () => {
    localStorage.removeItem('sessionBnd');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('sessionBnd');

    if (!token) {
      setLoading(false);
      return;
    }

    const decodedToken = jwt.decode(token);
    const currentTime = Date.now() / 1000;
    console.log(decodedToken, 'decodedToken+++++++++++++++++++');
    if (decodedToken?.exp < currentTime) {
      console.log('Token expired');
      localStorage.removeItem('sessionBnd');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const sessionBnd = localStorage.getItem('sessionBnd');
        const headers = {
          headers: { Authorization: `Bearer ${sessionBnd}` },
        };

        const response = await greenfeeDealsApi.get(
          `users/${decodedToken.id}`,
          headers
        );
        const apiResult = response.data.data;
        if (
          apiResult.data.role !== 'admin' &&
          apiResult.data.role !== 'golfClubAdmin' &&
          apiResult.data.role !== 'dealAdmin'
        ) {
          return;
        }
        login();
        dispatch(setUserDetails(apiResult.data));

        // Get and Safe GolfclubDetails
        const golfClub = await axios.post(
          `${config.API_URL}/api/v1/golfclubs/golfClubAdmin`,
          {},
          headers
        );
        console.log(golfClub, 'GolfClub Data');
        if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
          console.log('Error no Golfclub found for your Admin Account');
          setLoading(false);
          return;
        }
        const results = golfClub.data.data.data;
        dispatch(setGolfClubDetails(results));
        // Get and Membership Types
        const membershipTypes = await axios.get(
          `${config.API_URL}/api/v1/memberships`,
          headers
        );
        console.log(membershipTypes, 'membershipTypes Data');

        if (
          membershipTypes.data.status !== 'success' ||
          membershipTypes.data.results === 0
        ) {
          console.log('Error fetching membershipTypes');
          setLoading(false);
          return;
        }
        const membershipTypes_results = membershipTypes.data.data.data;
        dispatch(setMembershipTypes(membershipTypes_results));

        const discountCards = await axios.get(
          `${config.API_URL}/api/v1/discountcards`,
          headers
        );
        console.log(discountCards, 'discountCards Data');

        if (
          discountCards.data.status !== 'success' ||
          discountCards.data.results === 0
        ) {
          console.log('Error fetching DiscountCards');
          setLoading(false);
          return;
        }
        const results2 = discountCards.data.data.data;
        dispatch(setDiscountCards(results2));

        // Get and Safe Alliances
        const alliances = await axios.get(
          `${config.API_URL}/api/v1/alliances`,
          headers
        );
        console.log(alliances, 'alliances Data');

        if (
          alliances.data.status !== 'success' ||
          alliances.data.results === 0
        ) {
          console.log('Error fetching Alliances');
          setLoading(false);
          return;
        }

        const results3 = alliances.data.data.data;
        dispatch(setAlliances(results3));
        setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.error(error, 'AUTHCOntext ERROR');
        return error;
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div>
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
      </AuthContext.Provider>
    </div>
  );
};
