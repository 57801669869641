import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMenuCollapsed,
  setSubMenuCollapsed,
  setAdminSearchResults,
} from '../redux/actions/generalActions';
import { setGolfClubDetails } from '../redux/actions/golfClubActions';
import { Layout, Button, Affix, AutoComplete, Row, Col } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import greenfeeDealsApi from '../api/greenfeeDeals';
const { Header } = Layout;

function HeaderComponent() {
  const golfClubDetails = useSelector(
    (state) => state.golfClub.golfClubDetails
  );
  const dispatch = useDispatch();
  const menuCollapsed = useSelector((state) => state.general.menuCollapsed);
  const sessionBnd = localStorage.getItem('sessionBnd');
  const headers = {
    headers: { Authorization: `Bearer ${sessionBnd}` },
  };

  const userDetails = useSelector((state) => state.general.userDetails);
  const userRole = userDetails?.role;
  const adminSearchResults = useSelector(
    (state) => state.general.adminSearchResults
  );

  const toggleCollapsed = () => {
    const collapsed = !menuCollapsed;
    dispatch(setMenuCollapsed(collapsed));
    if (collapsed === true) {
      dispatch(setSubMenuCollapsed([]));
    }
  };

  const searchGolfClubs = async (values) => {
    try {
      if (values.length > 3) {
        const response = await greenfeeDealsApi.get(
          `golfclubs?name[regex]=(?i)${values}`,
          headers
        );
        const apiResult = response.data.data.data;
        const resultsList = [];
        apiResult.forEach((e) => {
          resultsList.push({
            key: e._id,
            value: e.name,
          });
        });

        dispatch(setAdminSearchResults(resultsList));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeGolfClub = async (values) => {
    const golfClubId = values.key;

    const golfClub = await greenfeeDealsApi.post(
      `/golfclubs/golfClubAdmin`,
      { id: golfClubId },
      headers
    );
    if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
      console.log('Error no Golfclub found for your Admin Account');
      return;
    }
    const results = golfClub.data.data.data;
    dispatch(setGolfClubDetails(results));
    // window.location.reload();
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Affix offsetTop={0}>
        <Header
          style={{
            backgroundColor: 'white',
            paddingLeft: 50,
          }}
        >
          <Row>
            <Col span={12}>
              <Button
                type="primary"
                onClick={toggleCollapsed}
                style={{ marginBottom: 16 }}
              >
                {React.createElement(
                  menuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined
                )}
              </Button>
              <span style={{ paddingLeft: 40, fontWeight: 700, fontSize: 10 }}>
                {golfClubDetails.name}{' '}
                {(userRole === 'admin' || userRole === 'dealAdmin') && (
                  <span> | {golfClubDetails._id.golfClub}</span>
                )}
              </span>
            </Col>
            <Col justify="end" align="end" span={12}>
              {(userRole === 'admin' || userRole === 'dealAdmin') && (
                <AutoComplete
                  onChange={searchGolfClubs}
                  onSelect={(key, both) => changeGolfClub(both)}
                  style={{
                    width: 500,
                  }}
                  options={adminSearchResults}
                  placeholder="Golfplatz wechseln"
                />
              )}
            </Col>
          </Row>
        </Header>
      </Affix>
    </div>
  );
}

export default HeaderComponent;
