import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Layout, Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setGolfClubDetails } from '../redux/actions/golfClubActions';
import {
  setDiscountCards,
  setMembershipTypes,
  setAlliances,
  setUserDetails,
} from '../redux/actions/generalActions';

import logo from './../assets/logo.png';
import greenfeeDealsApi from '../api/greenfeeDeals';
import axios from 'axios';
import config from '../config';
import jwt from 'jsonwebtoken';

const { Header, Footer, Content } = Layout;

function SignIn(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [auth, setAuth] = useState(false);
  const [, forceUpdate] = useState();

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const Login = async (values) => {
    try {
      // Login
      const response = await greenfeeDealsApi.post('users/login', {
        email: values.username,
        password: values.password,
      });
      const apiResult = response.data;
      if (
        apiResult.status !== 'success' ||
        (apiResult.data.user.role !== 'admin' &&
          apiResult.data.user.role !== 'golfClubAdmin' &&
          apiResult.data.user.role !== 'dealAdmin')
      ) {
        return;
      }

      localStorage.setItem('sessionBnd', apiResult.token);
      dispatch(setUserDetails(apiResult.data.user));

      try {
        const sessionBnd = localStorage.getItem('sessionBnd');
        const headers = {
          headers: { Authorization: `Bearer ${sessionBnd}` },
        };
        // Get and Safe GolfclubDetails
        const golfClub = await axios.post(
          `${config.API_URL}/api/v1/golfclubs/golfClubAdmin`,
          {},
          headers
        );

        if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
          console.log('Error no Golfclub found for your Admin Account');
          return;
        }
        const results = golfClub.data.data.data;
        dispatch(setGolfClubDetails(results));
        // Get and Membership Types
        const membershipTypes = await axios.get(
          `${config.API_URL}/api/v1/memberships`,
          headers
        );

        if (
          membershipTypes.data.status !== 'success' ||
          membershipTypes.data.results === 0
        ) {
          console.log('Error fetching membershipTypes');
          return;
        }
        const membershipTypes_results = membershipTypes.data.data.data;
        dispatch(setMembershipTypes(membershipTypes_results));

        const discountCards = await axios.get(
          `${config.API_URL}/api/v1/discountcards`,
          headers
        );
        if (
          discountCards.data.status !== 'success' ||
          discountCards.data.results === 0
        ) {
          console.log('Error fetching DiscountCards');
          return;
        }
        const results2 = discountCards.data.data.data;
        dispatch(setDiscountCards(results2));

        // Get and Safe Alliances
        const alliances = await axios.get(
          `${config.API_URL}/api/v1/alliances`,
          headers
        );

        if (
          alliances.data.status !== 'success' ||
          alliances.data.results === 0
        ) {
          console.log('Error fetching Alliances');
          return;
        }

        const alliancesResults = alliances.data.data.data;
        dispatch(setAlliances(alliancesResults));
        window.location.href = '/details';
      } catch (error) {
        console.log('Error fetching Alliances 1');
        console.error(error);
        return error;
      }
    } catch (error) {
      console.log('Error fetching Alliances 2');
      return;
    }
  };

  const debounceNavigate = debounce((path) => {
    navigate(path);
  }, 1000);

  useEffect(() => {
    forceUpdate({});
    const token = localStorage.getItem('sessionBnd');
    if (token) {
      const decodedToken = jwt.decode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken?.exp > currentTime) {
        window.location.href = '/details';
      }
    }
  }, []);

  return (
    <div>
      {auth && <Navigate to="/details" />}
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ backgroundColor: '#293642' }}></Header>
        <Content
          style={{
            backgroundColor: '#293642',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div>
            <Row>
              <Col span={24} justify="center" align="center">
                <img
                  src={logo}
                  alt="Logo"
                  width={200}
                  style={{ paddingBottom: 50, marginTop: -40 }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  name="horizontal_login"
                  layout="inline"
                  onFinish={Login}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Bitte geben Sie einen Benutzername ein!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Bitte geben Sie ein Passwort ein!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Passwort"
                    />
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          !form.isFieldsTouched(true) ||
                          form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                      >
                        Log in
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
        <Footer style={{ backgroundColor: '#293642' }}></Footer>
      </Layout>
    </div>
  );
}

export default SignIn;
