import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Select, Button, InputNumber } from 'antd';
import greenfeeDealsApi from '../api/greenfeeDeals';
import { useSelector, useDispatch } from 'react-redux';
import { setGolfClubDetails } from '../redux/actions/golfClubActions';
import { DeleteFilled } from '@ant-design/icons';

function EditCoursesComponent({ course }) {
  const sessionBnd = localStorage.getItem('sessionBnd');
  const headers = {
    headers: { Authorization: `Bearer ${sessionBnd}` },
  };

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const golfClubDetails = useSelector(
    (state) => state.golfClub.golfClubDetails
  );

  const userDetails = useSelector((state) => state.general.userDetails);
  const userRole = userDetails.role;
  const updateCourse = async (values) => {
    const update = values.courseValues;
    // Update Details
    const updateDetails = {
      name: update[0].name,
      holes: update[0].holes,
      requirement: update[0].requirement,
    };
    await greenfeeDealsApi.patch(
      `/golfclubs/${golfClubId}/courses/${course[0]._id}`,
      updateDetails,
      headers
    );
    //Update Pricing
    update.forEach(async (e) => {
      await greenfeeDealsApi.patch(
        `/golfclubs/${golfClubId}/standardpricing/${e.standardPricing._id}`,
        { prices: e.standardPricing.prices },
        headers
      );
    });

    // Insert new Pricing
    if (values.newPricing.holes && values.newPricing.prices[0]) {
      let isDayFee = undefined;
      if (values.newPricing.holes === 'dayfee') {
        values.newPricing.holes = undefined;
        isDayFee = true;
      }

      await greenfeeDealsApi.post(
        `/golfclubs/${golfClubId}/standardpricing`,
        {
          course: course[0]._id,
          numberHoles: values.newPricing.holes,
          prices: values.newPricing.prices,
          dayFee: isDayFee,
        },
        headers
      );
    }

    // Get Current Values from Server

    const golfClub = await greenfeeDealsApi.post(
      `/golfclubs/golfClubAdmin`,
      { id: golfClubId },
      headers
    );
    if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
      return;
    }
    const results = golfClub.data.data.data;

    // Update Redux Store
    dispatch(setGolfClubDetails(results));
    if (values.newPricing.prices[0]) {
      form.resetFields();
    }
  };
  const deleteStandardPricing = async (id) => {
    await greenfeeDealsApi.delete(
      `/golfclubs/${golfClubId}/standardpricing/${id}`,
      headers
    );
    // Get Current Values from Server
    const golfClub = await greenfeeDealsApi.post(
      `/golfclubs/golfClubAdmin`,
      { id: golfClubId },
      headers
    );
    if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
      console.log('Error no Golfclub found for your Admin Account');
      return;
    }
    const results = golfClub.data.data.data;

    // Update Redux Store
    dispatch(setGolfClubDetails(results));
    // window.location.reload();
  };
  const golfClubId = golfClubDetails._id.golfClub;

  useEffect(() => {
    form.resetFields();
  }, [course]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 15,
        width: '100%',
        padding: 10,
        marginTop: 20,
      }}
    >
      <Form
        form={form}
        onFinish={updateCourse}
        hideRequiredMark
        initialValues={{
          courseValues: course,
        }}
      >
        <Row>
          <Col span={4} className="standardCol" style={{ marginRight: 20 }}>
            <div>
              <h5>Name des Platzes</h5>
              <Form.Item name={['courseValues', 0, 'name']}>
                <Input size="small" />
              </Form.Item>
            </div>
            <div style={{ paddingTop: 15 }}>
              <h5>Platzgröße</h5>
              <Form.Item name={['courseValues', 0, 'holes']}>
                <Select size="small">
                  <Option value={36}>36</Option>
                  <Option value={27}>27</Option>
                  <Option value={18}>18</Option>
                  <Option value={9}>9</Option>
                  <Option value={6}>6</Option>
                  <Option value={3}>3</Option>
                </Select>
              </Form.Item>
            </div>
            <div style={{ paddingTop: 15 }}>
              <h5>Vorgaben</h5>
              <Row>
                <span className="ant-form-text">Für Montag bis Freitag</span>
                <Form.Item
                  name={['courseValues', 0, 'requirement', 'week']}
                  style={{ width: '100%' }}
                >
                  <Select size="small">
                    <Option value="HCP">HCP</Option>
                    <Option value="Platzreife">Platzreife</Option>
                    <Option value="offen">Offen</Option>
                  </Select>
                </Form.Item>
              </Row>
              <Row>
                <div>
                  <span className="ant-form-text">
                    Für Wochenende und Feiertage
                  </span>
                  <Form.Item
                    name={['courseValues', 0, 'requirement', 'weekend']}
                  >
                    <Select size="small">
                      <Option value="HCP">HCP</Option>
                      <Option value="Platzreife">Platzreife</Option>
                      <Option value="offen">Offen</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Row>
            </div>
          </Col>
          <Col span={19} className="standardCol">
            <Row>
              <div>
                <h5>Greenfee</h5>
                <span>
                  Greenfee für Gäste (DGV "R"-Hologramm) während der Hauptsaison
                </span>
              </div>
            </Row>
            <Row style={{ marginTop: 35, marginBottom: 15 }}>
              <Col flex="100px" className="pricingCol">
                <h6>Preis für ...</h6>
              </Col>
              <Col flex="95px" className="pricingCol">
                <h6>Mo</h6>
              </Col>
              <Col flex="95px" className="pricingCol">
                <h6>Di</h6>
              </Col>
              <Col flex="95px" className="pricingCol">
                <h6>Mi</h6>
              </Col>
              <Col flex="95px" className="pricingCol">
                <h6>Do</h6>
              </Col>
              <Col flex="95px" className="pricingCol">
                <h6>Fr</h6>
              </Col>
              <Col flex="95px" className="pricingCol">
                <h6>Sa</h6>
              </Col>
              <Col flex="95px" className="pricingCol">
                <h6>So & Ft</h6>
              </Col>
              <Col flex="40px"></Col>
            </Row>

            {course.map((pricing, index) => {
              return (
                <Row
                  key={index}
                  className="pricingRow"
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Form.Item
                    name={['courseValues', index, 'standardPricing', '_id']}
                  >
                    <span />
                  </Form.Item>
                  <Col flex="100px" className="pricingCol">
                    {pricing.standardPricing.numberHoles ? (
                      <span>{pricing.standardPricing.numberHoles}-Loch</span>
                    ) : (
                      <span>Tagesfee</span>
                    )}
                  </Col>
                  <Col flex="95px" className="pricingCol">
                    <Form.Item
                      name={[
                        'courseValues',
                        index,
                        'standardPricing',
                        'prices',
                        0,
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value} €`}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="95px" className="pricingCol">
                    <Form.Item
                      name={[
                        'courseValues',
                        index,
                        'standardPricing',
                        'prices',
                        1,
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value} €`}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="95px" className="pricingCol">
                    <Form.Item
                      name={[
                        'courseValues',
                        index,
                        'standardPricing',
                        'prices',
                        2,
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value} €`}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="95px" className="pricingCol">
                    <Form.Item
                      name={[
                        'courseValues',
                        index,
                        'standardPricing',
                        'prices',
                        3,
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value} €`}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="95px" className="pricingCol">
                    <Form.Item
                      name={[
                        'courseValues',
                        index,
                        'standardPricing',
                        'prices',
                        4,
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value} €`}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="95px" className="pricingCol">
                    <Form.Item
                      name={[
                        'courseValues',
                        index,
                        'standardPricing',
                        'prices',
                        5,
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value} €`}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="95px" className="pricingCol">
                    <Form.Item
                      name={[
                        'courseValues',
                        index,
                        'standardPricing',
                        'prices',
                        6,
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value} €`}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    flex="40px"
                    style={{ paddingTop: '5px', textAlign: 'right' }}
                  >
                    {course.length > 1 && (
                      <Button
                        danger
                        type="primary"
                        icon={<DeleteFilled />}
                        size="small"
                        onClick={() =>
                          deleteStandardPricing(pricing.standardPricing._id)
                        }
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
            <Row
              className="pricingRow"
              style={{
                marginTop: 15,
              }}
            >
              <Col flex="100px" className="pricingCol">
                <Form.Item name={['newPricing', 'holes']}>
                  <Select size="small">
                    <Option value={36}>36</Option>
                    <Option value={27}>27</Option>
                    <Option value={18}>18</Option>
                    <Option value={9}>9</Option>
                    <Option value={6}>6</Option>
                    <Option value={3}>3</Option>
                    <Option value="dayfee">Tagesfee</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="95px" className="pricingCol">
                <Form.Item name={['newPricing', 'prices', 0]}>
                  <InputNumber
                    formatter={(value) => `${value} €`}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col flex="95px" className="pricingCol">
                <Form.Item name={['newPricing', 'prices', 1]}>
                  <InputNumber
                    formatter={(value) => `${value} €`}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col flex="95px" className="pricingCol">
                <Form.Item name={['newPricing', 'prices', 2]}>
                  <InputNumber
                    formatter={(value) => `${value} €`}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col flex="95px" className="pricingCol">
                <Form.Item name={['newPricing', 'prices', 3]}>
                  <InputNumber
                    formatter={(value) => `${value} €`}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col flex="95px" className="pricingCol">
                <Form.Item name={['newPricing', 'prices', 4]}>
                  <InputNumber
                    formatter={(value) => `${value} €`}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col flex="95px" className="pricingCol">
                <Form.Item name={['newPricing', 'prices', 5]}>
                  <InputNumber
                    formatter={(value) => `${value} €`}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col flex="95px" className="pricingCol">
                <Form.Item name={['newPricing', 'prices', 6]}>
                  <InputNumber
                    formatter={(value) => `${value} €`}
                    size="small"
                    max={999}
                  />
                </Form.Item>
              </Col>
              <Col flex="40px"></Col>
            </Row>
            <Row
              style={{ width: '100%', paddingRight: 0, paddingBottom: 0 }}
              justify="end"
            >
              <Col>
                {(userRole === 'admin' || userRole === 'golfClubAdmin') && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: 60, minWidth: 85, minHeight: 40 }}
                  >
                    Speichern
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EditCoursesComponent;
