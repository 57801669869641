import axios from 'axios';
import config from '../config';

let sessionBnd;
let currentHeader;
const getSessionBnd = () => {
  sessionBnd = localStorage.getItem('sessionBnd');
  if (sessionBnd) {
    currentHeader = {
      headers: {
        Authorization: `Bearer ${sessionBnd}`,
      },
    };
  }
};

getSessionBnd();
export default axios.create({
  baseURL: `${config.API_URL}/api/v1`,
  currentHeader,
});
