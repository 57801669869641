import React, { useState, useEffect } from "react";
import { Layout, Form, Input, Button, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setStatusMessage } from "../redux/actions/generalActions";
import logo from "./../assets/logo.png";
import greenfeeDealsApi from "../api/greenfeeDeals";

const { Header, Footer, Content } = Layout;

function PasswordReset(props) {
  const { resetToken } = useParams();
  const statusMessage = useSelector((state) => state.general.statusMessage);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(); // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const resetPassword = async (values) => {
    if (values.password !== values.passwordConfirm) {
      dispatch(
        setStatusMessage(
          "Die eingegebenen Passwörter sind nicht identisch ;-) "
        )
      );
    } else {
      try {
        await greenfeeDealsApi.patch(`users/resetPassword/${resetToken}`, {
          password: values.password,
          passwordConfirm: values.passwordConfirm,
        });
        dispatch(
          setStatusMessage(
            "Dein Passwort wurde erfolgreich geändert. Du kannst Dich nun damit in der App einloggen!"
          )
        );
      } catch (error) {
        if (error === "Request failed with status code 400") {
          dispatch(
            setStatusMessage(
              "Das hat nicht geklappt, bitte überprüfe Dein neues Passwort!"
            )
          );
        } else {
          dispatch(
            setStatusMessage(
              "Es liegt ein technisches Problem vor. Bitte schreibe uns unter kontakt@greenfee-deals.de"
            )
          );
        }
      }
    }
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#293642",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Row>
        <Col span={24} justify="center" align="center">
          <img
            src={logo}
            alt="Logo"
            width="100%"
            style={{
              marginTop: "100px",
              marginBottom: "40px",
              maxWidth: "250px",
            }}
          />
          <h3
            style={{
              color: "white",
              fontSize: 16,
              textAlign: "center",
              marginTop: 20,
              marginBottom: 50,
              maxWidth: 400,
            }}
          >
            Bitte gib Dein neues Passwort ein und wiederhole es zur Sicherheit
            noch einmal. Dein Passwort muss mindestens 6 Zeichen lang sein und
            einen Buchstaben, eine Zahl und ein Sonderzeichen enthalten.
          </h3>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            onFinish={resetPassword}
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              maxWidth: "500px",
            }}
          >
            <Form.Item
              name="password"
              style={{ marginTop: "15px" }}
              rules={[
                {
                  required: true,
                  message: "Bitte gib ein Passwort ein",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Neues Passwort"
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirm"
              style={{ marginTop: "5px" }}
              rules={[
                {
                  required: true,
                  message: "Bitte wiederhole das Passwort",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Wiederholung"
              />
            </Form.Item>
            <Form.Item style={{ marginTop: "35px" }} shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  Abschicken
                </Button>
              )}
            </Form.Item>
          </Form>
          <h3
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "Normal",
              textAlign: "center",
              marginTop: 50,
              maxWidth: 500,
            }}
          >
            {statusMessage}
          </h3>
        </Col>
      </Row>
    </Layout>
  );
}

export default PasswordReset;
